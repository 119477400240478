import { theme } from "@project/common"
import styled from "styled-components"

export const StyledJointFacility = styled.div`
  #provision__zip_code__content {
    display: flex;
    row-gap: 10px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    a {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: ${theme.colors.link};
      text-decoration: underline;
    }
  }
  .ant-checkbox-input {
    width: 20px; /* Set your desired width */
    height: 20px; /* Set your desired height */
  }
  .ant-checkbox-inner {
    width: 20px; /* Set your desired width */
    height: 20px; /* Set your desired height */
  }
  .delete_action_container {
    display: flex;
    justify-content: space-between;

    .joint-facility-button-wrapper {
      margin-top: 16px;
      .delete-btn {
        background: #e00;
        color: white;
        border: none;
      }
    }
  }
  .col__content {
    @media screen and (max-width: 430px) {
      padding: 14px 0;
    }
  }
  .service_provided,
  label {
    @media screen and (max-width: 430px) {
      margin-bottom: 8px;
    }
  }

  #provision__zip_code__content--msg,
  button {
    @media screen and (max-width: 430px) {
      margin-top: 8px;
    }
  }
  .input-link {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .service_provided {
    display: flex;
    align-items: center;
  }
`
