import {
  ActionBtns,
  Button,
  Card,
  CheckBox,
  ErrorMassage,
  Grid,
  InputAreaField,
  InputField,
  PREFECTURES,
  PREFECTURE_OPTIONS,
  SelectInput,
  allowOnlyNumberAndHyphen,
  scrollToFirstErrorField,
} from "@project/common"
import { useFormik } from "formik"
import Link from "next/link"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"

import japanPostalCode from "japan-postal-code"
import router from "next/router"

import { Popconfirm } from "antd"
import {
  JointFacilityFormSchema,
  JointFacilityFormValues,
} from "../../../types"
import { StyledJointFacility } from "./jointFacilityForm.style"

interface JointFacilityFormProps {
  title?: string
  isLoading?: boolean
  defaultValue?: JointFacilityFormValues | undefined
  onSubmit?: (values: JointFacilityFormValues) => void
  handleDelete?: () => void
  isDeleteLoading?: boolean
}

export const JointFacilityForm = ({
  title,
  onSubmit,
  isLoading,
  defaultValue,
  handleDelete,
  isDeleteLoading,
}: JointFacilityFormProps): JSX.Element => {
  const { t } = useTranslation()

  const initialValues: JointFacilityFormValues = {
    facility_name: "",
    facility_name_furigana: "",
    facility_number: null,
    other_service_type_text: "",
    service_type01: 0,
    service_type02: 0,
    service_type03: 0,
    other_service_type: 0,
    zip_code: "",
    prefecture: null,
    address: "",
    pic: "",
    email: "",
    tel_no: "",
    fax_no: "",
    remark_text: "",
    po_box: "",
  }

  const formik = useFormik<JointFacilityFormValues>({
    initialValues: defaultValue || initialValues,
    validationSchema: JointFacilityFormSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      onSubmit({
        ...values,
        service_type01: values?.service_type01 ? 1 : 0,
        service_type02: values?.service_type02 ? 1 : 0,
        service_type03: values?.service_type03 ? 1 : 0,
        other_service_type: values?.other_service_type ? 1 : 0,
        other_service_type_text: values?.other_service_type
          ? values?.other_service_type_text
          : "",
      })
    },
  })
  useEffect(() => {
    if (formik.submitCount == 0) return
    if (formik.isValid) return
    scrollToFirstErrorField(formik.errors)
  }, [formik.submitCount, formik.isValid])

  const setAddressFromPostCode = async () => {
    japanPostalCode.get(formik?.values?.zip_code, function (address) {
      formik.setFieldValue("address", address?.city)
      const prefectureNumber =
        PREFECTURE_OPTIONS.find((item) => {
          return item.label == address?.prefecture
        })?.label || null
      formik.setFieldValue("prefecture", prefectureNumber)
    })
  }

  return (
    <StyledJointFacility>
      <Card title={title}>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <Grid
              labelContent={t("Joint Facility Name")}
              className={"row__header"}
              labelSpan={5}
              background
              required
            >
              <InputField
                name={"facility_name"}
                id={"facility_name"}
                placeholder={t("Enter facility name")}
                padding={"8px"}
                borderRadius={"4px"}
                info={t("(within 50 char)")}
                maxLength={50}
                value={formik.values.facility_name}
                error={
                  formik.touched?.facility_name && formik.errors?.facility_name
                    ? t(`${formik.errors?.facility_name}`)
                    : ""
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid
              labelContent={t("Hiragana")}
              className={"row__header"}
              labelSpan={5}
              background
            >
              <InputField
                name={"facility_name_furigana"}
                id={"facility_name_furigana"}
                placeholder={t("Enter name in Hiragana")}
                padding={"8px"}
                borderRadius={"4px"}
                info={t("(within 100 char)")}
                maxLength={100}
                value={formik.values?.facility_name_furigana}
                error={
                  formik.touched?.facility_name_furigana &&
                  formik.errors?.facility_name_furigana
                    ? t(`${formik.errors?.facility_name_furigana}`)
                    : ""
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid
              labelContent={t("Office Number")}
              className={"row__header"}
              labelSpan={5}
              background
              required
            >
              <InputField
                name={"facility_number"}
                id={"facility_number"}
                placeholder={t("例：0123456789")}
                padding={"8px"}
                borderRadius={"4px"}
                maxLength={30}
                width={"320px"}
                value={formik.values?.facility_number}
                error={
                  formik.touched?.facility_number &&
                  formik.errors?.facility_number
                    ? `${formik.errors?.facility_number}`
                    : ""
                }
                onChange={({ target: { value } }) => {
                  formik.setFieldValue(
                    "facility_number",
                    allowOnlyNumberAndHyphen(value),
                  )
                }}
                onBlur={formik.handleBlur}
                subInfo={t("(half width Number within 30 char)")}
              />
            </Grid>
            <Grid
              labelContent={t("Service Provided")}
              className={"row__header"}
              labelSpan={5}
              background
              required
            >
              <div id={"service_provided"}>
                <CheckBox
                  name={"service_type01"}
                  label={t("Employment Type A")}
                  value={formik.values.service_type01}
                  checked={formik.values.service_type01}
                  onChange={({ target: { checked } }) =>
                    formik.setFieldValue("service_type01", checked)
                  }
                />
                <CheckBox
                  name={"service_type02"}
                  label={t("Employment Type B")}
                  value={formik.values.service_type02}
                  checked={formik.values.service_type02}
                  onChange={({ target: { checked } }) =>
                    formik.setFieldValue("service_type02", checked)
                  }
                />
                <CheckBox
                  name={"service_type03"}
                  label={t("Employment Transition Support")}
                  value={formik.values.service_type03}
                  checked={formik.values.service_type03}
                  onChange={({ target: { checked } }) =>
                    formik.setFieldValue("service_type03", checked)
                  }
                />
                <CheckBox
                  name={"other_service_type"}
                  label={t("Other")}
                  value={formik.values.other_service_type}
                  checked={formik.values.other_service_type}
                  onChange={({ target: { checked } }) =>
                    formik.setFieldValue("other_service_type", checked)
                  }
                />
                {formik.values.other_service_type ? (
                  <InputField
                    name={"other_service_type_text"}
                    id={"other_service_type_text"}
                    padding={"8px"}
                    borderRadius={"4px"}
                    width={"320px"}
                    value={formik.values?.other_service_type_text}
                    error={
                      formik.touched?.other_service_type_text &&
                      formik.errors?.other_service_type_text
                        ? `${formik.errors?.other_service_type_text}`
                        : ""
                    }
                    onChange={({ target: { value } }) => {
                      formik.setFieldValue("other_service_type_text", value)
                    }}
                    onBlur={formik.handleBlur}
                    style={{ marginTop: "12px" }}
                  />
                ) : null}
                {formik.errors?.service_provided && (
                  <ErrorMassage message={formik.errors?.service_provided} />
                )}
              </div>
            </Grid>
            <Grid
              labelContent={t("Po box")}
              className={"row__header"}
              labelSpan={5}
              background
            >
              <div id={"provision__zip_code__content"}>
                <div className={"input-link"}>
                  <InputField
                    name={"zip_code"}
                    id={"zip_code"}
                    placeholder={t("例：001-0010")}
                    padding={"8px"}
                    borderRadius={"4px"}
                    width={"320px"}
                    maxLength={8}
                    value={formik.values?.zip_code}
                    error={
                      formik.touched?.zip_code && formik.errors?.zip_code
                        ? t(`${formik.errors?.zip_code}`)
                        : ""
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    subInfo={t("(within 8 characters)")}
                  />
                  <Link href={"/"} id={"provision__zip_code__content--msg"}>
                    {t(
                      "*If you are unsure of your postal code, please check the postal code on the Japan Post Group website.",
                    )}
                  </Link>
                </div>
                <Button
                  btnText={t("Autofill address")}
                  shape={"round"}
                  onClick={setAddressFromPostCode}
                  disabled={!formik.values.zip_code}
                />
              </div>
            </Grid>
            <Grid
              labelContent={t("Prefecture")}
              className={"row__header"}
              labelSpan={5}
              background
              required
            >
              <SelectInput
                width={"300px"}
                name={"prefecture"}
                options={PREFECTURES}
                onChange={(prefecture) =>
                  formik.setFieldValue("prefecture", prefecture)
                }
                value={formik.values.prefecture || null}
                error={
                  formik.touched?.prefecture && formik.errors?.prefecture
                    ? `${formik.errors?.prefecture}`
                    : ""
                }
                placeholder={"---"}
              />
            </Grid>
            <Grid
              labelContent={t("Municipality/Address")}
              className={"row__header"}
              labelSpan={5}
              background
            >
              <InputField
                name={"address"}
                id={"address"}
                placeholder={t("例：山本　太郎")}
                padding={"8px"}
                borderRadius={"4px"}
                info={t("(within 200 char)")}
                maxLength={200}
                value={formik.values?.address}
                error={
                  formik.touched?.address && formik.errors?.address
                    ? t(`${formik.errors?.address}`)
                    : ""
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid
              labelContent={t("PIC")}
              className={"row__header"}
              labelSpan={5}
              background
            >
              <InputField
                name={"pic"}
                id={"pic"}
                placeholder={t("")}
                padding={"8px"}
                borderRadius={"4px"}
                info={t("(within 100 char)")}
                maxLength={100}
                value={formik.values?.pic}
                error={
                  formik.touched?.pic && formik.errors?.pic
                    ? t(`${formik.errors?.pic}`)
                    : ""
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid
              labelContent={t("Email Address")}
              className={"row__header"}
              labelSpan={5}
              background
            >
              <InputField
                name={"email"}
                id={"email"}
                type={"email"}
                placeholder={t("例: easyy@email.com")}
                padding={"8px"}
                borderRadius={"4px"}
                subInfoMt={"0px"}
                subInfo={t("(半角英数字100文字以内)")}
                maxLength={100}
                width={"319px"}
                value={formik.values?.email}
                error={
                  formik.touched?.email && formik.errors?.email
                    ? t(`${formik.errors?.email}`)
                    : ""
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid
              labelContent={t("Phone Number")}
              className={"row__header"}
              labelSpan={5}
              background
            >
              <InputField
                name={"tel_no"}
                id={"tel_no"}
                placeholder={t("例：03-1234-5678")}
                padding={"8px"}
                width={"320px"}
                borderRadius={"4px"}
                maxLength={30}
                value={formik.values?.tel_no}
                error={
                  formik.touched?.tel_no && formik.errors?.tel_no
                    ? t(`${formik.errors?.tel_no}`)
                    : ""
                }
                onChange={({ target: { value } }) => {
                  formik.setFieldValue(
                    "tel_no",
                    allowOnlyNumberAndHyphen(value),
                  )
                }}
                onBlur={formik.handleBlur}
                subInfo={t("(Numbers and hyphens (-) up to 30 characters)")}
              />
            </Grid>
            <Grid
              labelContent={t("Fax Number")}
              className={"row__header"}
              labelSpan={5}
              background
            >
              <InputField
                name={"fax_no"}
                id={"fax_no"}
                placeholder={t("例：03-8765-4321")}
                padding={"8px"}
                borderRadius={"4px"}
                width={"320px"}
                maxLength={30}
                subInfo={t("(Numbers and hyphens (-) up to 30 characters)")}
                value={formik.values?.fax_no}
                error={
                  formik.touched?.fax_no && formik.errors?.fax_no
                    ? t(`${formik.errors?.fax_no}`)
                    : ""
                }
                onChange={({ target: { value } }) => {
                  formik.setFieldValue(
                    "fax_no",
                    allowOnlyNumberAndHyphen(value),
                  )
                }}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid
              labelContent={t("Remarks")}
              className={"row__header"}
              labelSpan={5}
              background
            >
              <InputAreaField
                name={"remark_text"}
                id={"remark_text"}
                rows={4}
                placeholder={t("--")}
                padding={"8px"}
                borderRadius={"4px"}
                info={t("(Within 250 char)")}
                maxLength={250}
                value={formik.values?.remark_text}
                error={
                  formik.touched?.remark_text && formik.errors?.remark_text
                    ? t(`${formik.errors?.remark_text}`)
                    : ""
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid>
          </div>
          <div className={"delete_action_container"}>
            <ActionBtns justify={"none"} mt={"16px"}>
              <Button
                btnText={t("Cancel")}
                shape={"round"}
                type={"default"}
                onClick={() => router.push("/joint-facility")}
                disabled={isLoading}
              />
              <Button
                btnText={t("Save")}
                shape={"round"}
                type={"primary"}
                htmlType={"submit"}
                isLoading={isLoading}
              />
            </ActionBtns>
            {defaultValue?.facility_name && (
              <div className={"joint-facility-button-wrapper"}>
                <Popconfirm
                  title={t("Deleting.Is that OK?")}
                  onConfirm={handleDelete}
                  okText={t("OK")}
                  cancelText={t("Cancel")}
                  okButtonProps={{ size: "middle" }}
                  cancelButtonProps={{ size: "middle" }}
                >
                  <Button
                    btnText={t("Delete")}
                    shape={"round"}
                    className={"delete-btn"}
                    isLoading={isDeleteLoading}
                  />
                </Popconfirm>
              </div>
            )}
          </div>
        </form>
      </Card>
    </StyledJointFacility>
  )
}
